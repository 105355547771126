html, body {
  height: 100%;
}
#root {
  height: 100%;

}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.main-container {
  padding: 24px 12px;
  width: calc(100% - 24px);
}

.full-height {
  min-height: calc(100% - 48px);
}

.ant-tabs-nav-more {
  display: none;
}

.link {
  max-height: 55px;
  padding: 5px 20px;
  border-radius: 5px;
  text-align: center;
  cursor: pointer;
  text-decoration: none;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  height: auto;
  position: relative;
  width: 100%;
  appearance: none;
  box-sizing: border-box;
  vertical-align: middle;
  overflow-wrap: break-word;
  word-break: break-word;
  hyphens: auto;
  white-space: normal;
  transition: box-shadow 0.25s cubic-bezier(0.08, 0.59, 0.29, 0.99) 0s, border-color 0.25s cubic-bezier(0.08, 0.59, 0.29, 0.99) 0s, transform 0.25s cubic-bezier(0.08, 0.59, 0.29, 0.99) 0s, background-color 0.25s cubic-bezier(0.08, 0.59, 0.29, 0.99) 0s;
  margin-bottom: 16px;
}

a {
  text-decoration: none;  
}

.hide-scrollbar::-webkit-scrollbar {
  display: none;
}